import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import "./AddResourceModal.css"; // Add styling as needed

const AddResourceModal = ({ onClose, onSave }) => {
  const [resourceName, setResourceName] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [introduction, setIntroduction] = useState("");
  const [courseContent, setCourseContent] = useState("");
  const [isPublished, setIsPublished] = useState(true); // Initialize as true

  const handleSave = () => {
    onSave({ resourceName, title, description, introduction, courseContent, isPublished });
  };

  return (
    <div className="resource-modal-backdrop">
      <div className="resource-modal-container">
        <h2>Add New Resource</h2>

        {/* Resource Name Field */}
        <div className="form-group">
          <label>Resource Name</label>
          <input
            type="text"
            value={resourceName}
            onChange={(e) => setResourceName(e.target.value)}
            placeholder="Enter resource name"
          />
        </div>

        {/* Title Field */}
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter title"
          />
        </div>

        {/* Introduction Field */}
        <div className="form-group">
          <label>Introduction</label>
          <ReactQuill
            value={introduction}
            onChange={setIntroduction}
            placeholder="Enter introduction"
          />
        </div>

        {/* Description Field */}
        <div className="form-group">
          <label>About the Course</label>
          <ReactQuill
            value={description}
            onChange={setDescription}
            placeholder="Enter description"
          />
        </div>

        {/* Course Content Field */}
        <div className="form-group">
          <label>Course Content</label>
          <ReactQuill
            value={courseContent}
            onChange={setCourseContent}
            placeholder="Enter course content"
          />
        </div>

        {/* Published Toggle */}
        <div className="form-group">
          <label>Publish Resource</label>
          <label className="switch">
            <input 
              type="checkbox" 
              checked={isPublished} 
              onChange={() => setIsPublished(!isPublished)} 
            />
            <span className="slider round"></span>
          </label>
          <span>{isPublished ? "Published" : "Unpublished"}</span>
        </div>

        {/* Modal Actions */}
        <div className="modal-actions">
          <button className="btn-primary" onClick={onClose}>
            Close
          </button>
          <button className="btn-secondary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddResourceModal;
