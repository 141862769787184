// ManageCodes.js
import React, { useState, useEffect } from "react";
import "./ManageCodes.css";
import CustomTable from "../../components/CustomTable/CustomTable";
import {
  getAllCodes,
  createAvailableCode,
  updateAvailableCode,
  deleteAvailableCode,
} from "../../services/apiService";
import { useTranslation } from "react-i18next";
import AddCodeModal from "./AddCodeModal/AddCodeModal";

const ManageCodes = () => {
  const { t } = useTranslation();
  const [codes, setCodes] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchCodes = async () => {
    try {
      const response = await getAllCodes();
      setCodes(response);
    } catch (error) {
      console.error("Error fetching codes:", error);
    }
  };

  useEffect(() => {
    fetchCodes();
  }, []);

  const handleAddCodeClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSaveCode = async (newCode) => {
    try {
      console.log("New code data:", newCode);
      await createAvailableCode(newCode);
      fetchCodes();
      handleModalClose();
    } catch (error) {
      console.error("Error creating new code:", error);
    }
  };

  const handleUpdateCode = async (updatedData) => {
    const { id, code, status } = updatedData;
    try {
      console.log(code, status);
      await updateAvailableCode(id, code, status);
      fetchCodes();
    } catch (error) {
      console.error("Error updating code:", error);
    }
  };

  const handleDeleteCode = async (id) => {
    try {
      await deleteAvailableCode(id);
      fetchCodes();
    } catch (error) {
      console.error("Error deleting code:", error);
    }
  };

  const headers = ["Code", "Status"];

  return (
    <div className="container">
      <h1 className="title">Manage Codes</h1>
      <button className="btn-secondary" onClick={handleAddCodeClick}>
        Add Code
      </button>
      <CustomTable
        data={codes}
        headers={headers}
        updateFunction={handleUpdateCode}
        deleteFunction={handleDeleteCode}
      />
      
      {isModalOpen && (
        <AddCodeModal onClose={handleModalClose} onSave={handleSaveCode} />
      )}
    </div>
  );
};

export default ManageCodes;
