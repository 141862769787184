import React, { useState } from "react";
import "./AddCodeModal.css"; // Add styling as needed

const AddCodeModal = ({ onClose, onSave }) => {
  const [code, setCode] = useState("");

  const handleSave = () => {
    onSave({ code, status: "active" });
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <h2>Add New Code</h2>
        <div className="form-group">
          <label>Code</label>
          <input
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter code"
          />
        </div>
        <div className="modal-actions">
          <button className="btn-primary" onClick={onClose}>
            Close
          </button>
          <button className="btn-secondary" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCodeModal;
