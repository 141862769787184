// src/services/apiService.js

import axios from "axios";
import env from "react-dotenv";
import { convertKeysInResponseToLowerCase } from "./utils";

const API_URL = env.API_URL;

export const getAllUsers = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER",
      method_name: "viewUsers",
      params: {},
    });
    const lowerCasedData = convertKeysInResponseToLowerCase(response.data);
    console.log(lowerCasedData);
    return lowerCasedData;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER",
      method_name: "createUser",
      params: userData,
    });
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const updateUser = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER",
      method_name: "updateUser",
      params: userData,
    });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

export const deleteUser = async (uuid) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER",
      method_name: "deleteUser",
      params: { id: uuid },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER",
      method_name: "signIn",
      params: { email, password },
    });
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    throw error;
  }
};

export const getAllCodes = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_CODE",
      method_name: "getAllActiveCodes",
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching codes:", error);
    throw error;
  }
};

export const createAvailableCode = async ({ code, status }) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_CODE",
      method_name: "createAvailableCode",
      params: { code, status },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating new code:", error);
    throw error;
  }
};
export const updateAvailableCode = async (id, code, status) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_CODE",
      method_name: "updateAvailableCode",
      params: { id, code, status },
    });
    console.log(code, status, response);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const deleteAvailableCode = async (id) => {
  await axios.post(`${API_URL}`, {
    systemId: "AVAILABLE_CODE",
    method_name: "deleteAvailableCode",
    params: { id },
  });
};

export const getAllResources = async () => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_RESOURCE",
      method_name: "getAllResources",
    });
    const lowerCasedData = convertKeysInResponseToLowerCase(response.data);
    console.log(lowerCasedData);
    return lowerCasedData;
  } catch (error) {
    console.error("Error fetching resources:", error);
    throw error;
  }
};

export const createAvailableResource = async (newResource) => {
  console.log(newResource);
  const response = await axios.post(`${API_URL}`, {
    systemId: "AVAILABLE_RESOURCE",
    method_name: "createAvailableResource",
    params: {
      resourceName: newResource.resourceName,
      resourceTitle: newResource.title,
      resourceDescription: newResource.description,
      introduction: newResource.introduction,
      courseContent: newResource.courseContent,
      isPublished: newResource.isPublished
    },
  });
  return response.data;
};
export const updateAvailableResource = async (resourceData) => {
  try {
    console.log(resourceData);
    const response = axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_RESOURCE",
      method_name: "updateAvailableResource",
      params: {
        id: resourceData.id,
        resourceName: resourceData.resourcename,
        resourceTitle: resourceData.resourcetitle,
        resourceDescription: resourceData.resourcedescription,
        introduction: resourceData.introduction,
        courseContent: resourceData.coursecontent,
        isPublished: resourceData.ispublished
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error updating resource:", error);
    throw error;
  }
};

export const deleteAvailableResource = async (resourceId) => {
  try {
    const response = axios.post(`${API_URL}`, {
      systemId: "AVAILABLE_RESOURCE",
      method_name: "deleteAvailableResource",
      params: {
        id: resourceId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting resource:", error);
    throw error;
  }
};
export const getUserResourceMapping = async (userId) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER_RESOURCE_MAPPING",
      method_name: "findByUserId",
      params: {
        userId: userId,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user resource mapping:", error);
    throw error;
  }
};
export const createUserResourceMapping = async (
  userId,
  resourceId,
  codeId,
  uniqueUrl
) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER_RESOURCE_MAPPING",
      method_name: "createUserResourceMapping",
      params: {
        userId: userId,
        resourceId: resourceId,
        codeId: codeId,
        url: uniqueUrl,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error creating user resource mapping:", error);
    throw error;
  }
};
export const deleteUserResourceMapping = async (id) => {
  try {
    const response = await axios.post(`${API_URL}`, {
      systemId: "USER_RESOURCE_MAPPING",
      method_name: "deleteUserResourceMapping",
      params: {
        id: id,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting user resource mapping:", error);
    throw error;
  }
};
export const redeemResource = async (redemptionCode, userEmail, materialId) => {
  try {
    const response = await axios.post(
      "https://thai.cyberphoenix.com/wp-json/cp-api/v1/redeem",
      {
        // redemption_code: "UWXPYgN9F56z",
        redemption_code: redemptionCode,
        user_email: userEmail,
        material_id: materialId,
        // material_id: "book_comptia_pentest",
      },
      {
        headers: {
          "x-api-key": "e23k1F&7hJw90mNlXbZ4!VpQ7sR2p8M@",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error redeeming resource:", error);
    throw error;
  }
};
