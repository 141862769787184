import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./CustomTable.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import EditModal from "../EditModal/EditModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal/DeleteConfirmationModal";

const CustomTable = ({
  data,
  headers,
  updateFunction,
  deleteFunction,
  editModalProps,
  useEmoNbrForDelete = false,
  rowsPerPage = 10,
}) => {
  const { t } = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const openEditModal = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedItem(null);
  };

  const openDeleteModal = (item) => {
    setSelectedItem(item);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedItem(null);
  };

  const saveChanges = async (updatedData) => {
    try {
      await updateFunction(updatedData);
      console.log("updated_data", updatedData);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleDeleteClick = async () => {
    try {
      if (selectedItem) {
        const identifier = useEmoNbrForDelete
          ? selectedItem.moneyordernumber
          : selectedItem.id;
        await deleteFunction(identifier);
        closeDeleteModal();
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const translatedHeaders = headers.map(
    (header) => t(`labels.${header.toLowerCase().replace(/ /g, "")}`) || header
  );

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + rowsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <table className="custom-table">
        <thead>
          <tr>
            {translatedHeaders.map((header, index) => (
              <th key={index}>{header}</th>
            ))}
            <th>{t("labels.action")}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((item) => (
            <tr key={item.id}>
              {headers.map((header, index) => {
                const key = header.toLowerCase().replace(/ /g, "");
                return <td key={index}>{item[key] || "-"}</td>;
              })}
              <td>
                <button
                  onClick={() => openEditModal(item)}
                  className="action-button"
                  aria-label={t("labels.edit")}
                >
                  <FaEdit />
                </button>
                <button
                  onClick={() => openDeleteModal(item)}
                  className="action-button"
                  aria-label={t("labels.delete")}
                >
                  <FaTrash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="pagination-controls">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          {t("pagination.previous")}
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            className={index + 1 === currentPage ? "active" : ""}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          {t("pagination.next")}
        </button>
      </div>

      {isEditModalOpen && (
        <EditModal
          data={selectedItem}
          closeModal={closeEditModal}
          onSave={saveChanges}
          {...editModalProps}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          closeModal={closeDeleteModal}
          onDelete={handleDeleteClick}
        />
      )}
    </div>
  );
};

export default CustomTable;
