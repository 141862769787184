import React, { useState, useEffect } from "react";
import "./ManageResources.css";
import CustomTable from "../../components/CustomTable/CustomTable";
import {
  getAllResources,
  createAvailableResource,
  updateAvailableResource,
  deleteAvailableResource,
} from "../../services/apiService";
import { useTranslation } from "react-i18next";
import AddResourceModal from "./AddResourceModal/AddResourceModal";

const ManageResources = () => {
  const { t } = useTranslation();
  const [resources, setResources] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchResources = async () => {
    try {
      const response = await getAllResources();
      setResources(response);
    } catch (error) {
      console.error("Error fetching resources:", error);
    }
  };

  useEffect(() => {
    fetchResources();
  }, []);

  const handleAddResourceClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSaveResource = async (newResource) => {
    try {
      console.log(newResource);
      await createAvailableResource(newResource);
      fetchResources();
      handleModalClose();
    } catch (error) {
      console.error("Error creating new resource:", error);
    }
  };

  const headers = ["Id", "Resource Title"];

  return (
    <div className="container">
      <h1 className="title">Manage Resources</h1>
      <button className="btn-secondary" onClick={handleAddResourceClick}>
        Add Resource
      </button>
      <CustomTable
        data={resources}
        headers={headers}
        updateFunction={async (resource) => {
          await updateAvailableResource(resource);
          fetchResources();
        }}
        deleteFunction={async (resourceId) => {
          await deleteAvailableResource(resourceId);
          fetchResources();
        }}
        editModalProps={{ isResourceModal: true }} // Pass the prop for specific layout
      />

      {isModalOpen && (
        <AddResourceModal
          onClose={handleModalClose}
          onSave={handleSaveResource}
        />
      )}
    </div>
  );
};

export default ManageResources;
