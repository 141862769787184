import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getUserResourceMapping,
  getAllResources,
  getAllCodes,
  redeemResource,
  createUserResourceMapping,
  deleteUserResourceMapping,
  updateUser,
} from "../../services/apiService";
import "./UserResourceMapping.css";
import { FaEdit, FaTrash } from "react-icons/fa";
import EditModal from "../../components/EditModal/EditModal";

const UserMappingPage = () => {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [resources, setResources] = useState([]);
  const [allResources, setAllResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true); // Set loading to true
      try {
        const data = await getUserResourceMapping(userId);
        setUserData(data[0].user); // Assuming response is an array
        setResources(data);
      } catch (error) {
        console.error("Error fetching user mapping data:", error);
      }
      setLoading(false); // Set loading to false
    };

    fetchUserData();
  }, [userId]);

  const fetchAllResources = async () => {
    setLoading(true);
    try {
      const availableResources = await getAllResources();
      console.log(availableResources);

      const assignedResourceIds = resources
        .filter((resource) => resource.resource !== null)
        .map((resource) => resource.resource.id);

      const unassignedResources = availableResources.filter(
        (resource) => !assignedResourceIds.includes(resource.id)
      );

      setAllResources(unassignedResources);
    } catch (error) {
      console.error("Error fetching all resources:", error);
    }
    setLoading(false);
  };

  const handleAssignResourceClick = () => {
    setIsModalOpen(true);
    fetchAllResources();
  };

  const handleCheckboxChange = (resourceId) => {
    setSelectedResources((prevSelectedResources) =>
      prevSelectedResources.includes(resourceId)
        ? prevSelectedResources.filter((id) => id !== resourceId)
        : [...prevSelectedResources, resourceId]
    );
  };

  const handleEditProfile = () => {
    setIsEditing(true);
    setIsUserModalOpen(true);
  };

  const handleSave = async (updatedData) => {
    setLoading(true);
    try {
      await updateUser({ ...updatedData, uuid: userData.uuid });
      setUserData((prevData) => ({ ...prevData, ...updatedData }));
      alert("User profile updated successfully!");
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Failed to update user profile.");
    }
    setLoading(false);
  };

  const handleSaveResources = async () => {
    setLoading(true);
    try {
      for (const resourceId of selectedResources) {
        const codes = await getAllCodes();
        const activeCode = codes.find((code) => code.status === "active");
  
        if (!activeCode) {
          throw new Error("No active codes available.");
        }
  
        const resource = allResources.find((res) => res.id === resourceId);
  
        if (resource) {
          // Redeem the resource
          const redemptionResponse = await redeemResource(
            activeCode.code,
            userData.email,
            resource.resourcename
          );
  
          // Check if the redemption response is successful
          if (redemptionResponse && redemptionResponse.access_link) {
            console.log("Redemption successful:", redemptionResponse);
  
            // Proceed with creating the user resource mapping
            await createUserResourceMapping(
              userId,
              resourceId,
              activeCode.id,
              redemptionResponse.access_link
            );
  
            console.log(
              `Resource ${resource.resourcename} assigned successfully to user ${userData.email}.`
            );
          } else {
            console.error(
              `Failed to redeem resource ${resource.resourcename} for user ${userData.email}.`
            );
            throw new Error(
              `Redemption failed for resource ${resource.resourcename}.`
            );
          }
        }
      }
  
      alert("Resources assigned successfully with new active codes!");
      setIsModalOpen(false);
      setSelectedResources([]);
      setResources(await getUserResourceMapping(userId)); 
    } catch (error) {
      console.error("Error assigning resources:", error);
      alert("Error assigning resources.");
    }
    setLoading(false);
  }; 
  

  const handleDeleteResource = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this resource mapping?"
    );
    if (!confirmDelete) return;

    setLoading(true);
    try {
      await deleteUserResourceMapping(id);
      alert("Resource mapping deleted successfully!");
      setResources((prevResources) =>
        prevResources.filter((resource) => resource.id !== id)
      );
    } catch (error) {
      console.error("Error deleting resource mapping:", error);
      alert("Error deleting resource mapping.");
    }
    setLoading(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredResources = allResources.filter((resource) =>
    resource.resourcename.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!userData) {
    return <p>Loading user data...</p>;
  }

  return (
    <div className="container">
      <h1 className="title">User Resources</h1>
      <div className="user-mapping-container">
        <div className="user-map-info">
          <div className="user-map-details">
            <div className="user-map-name">
              {userData.firstName} {userData.surname}
              <span className="user-map-status">Active</span>
            </div>
            <div className="user-map-email">{userData.email}</div>
            <div className="user-map-last-login">
              Last login: Sep 23, 2024 7:38 am
            </div>
          </div>
          <div className="user-map-dimensions">
            <a
              href="#"
              className="edit-map-profile"
              onClick={handleEditProfile}
            >
              Edit Profile
            </a>
          </div>
        </div>

        <div className="resource-section">
          <button
            className="assign-resource-button"
            onClick={handleAssignResourceClick}
          >
            Assign Resource
          </button>
          <table className="resource-table">
            <thead>
              <tr>
                <th>Resource Name</th>
                <th>URL</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Action</th> {/* Added Action Column */}
              </tr>
            </thead>
            <tbody>
              {resources
                .filter((resource) => resource.resource !== null) // Filter out resources with null values
                .map((resource) => (
                  <tr key={resource.id}>
                    <td>{resource.resource.resourceTitle}</td>
                    <td>
                      <a
                        href={resource.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Resource
                      </a>
                    </td>
                    <td>{resource.code ? resource.code.status : "N/A"}</td>
                    <td>{new Date(resource.createdAt).toLocaleString()}</td>
                    <td>
                      <button
                        className="delete-button"
                        onClick={() => handleDeleteResource(resource.id)}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Select Resources to Assign</h2>
            <input
              type="text"
              placeholder="Search resources..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-box"
            />
            <div className="resource-list">
              {filteredResources.map((resource) => (
                <div key={resource.id} className="resource-item">
                  <input
                    type="checkbox"
                    value={resource.id}
                    onChange={() => handleCheckboxChange(resource.id)}
                    checked={selectedResources.includes(resource.id)}
                    className="checkbox"
                  />
                  <label className="resource-label">
                    <strong>{resource.resourcetitle}</strong>
                    <span className="resource-id"> (ID: {resource.id})</span>
                  </label>
                </div>
              ))}
            </div>
            <div className="modal-buttons">
              <button className="save-button" onClick={handleSaveResources}>
                Save
              </button>
              <button
                className="cancel-button"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {isUserModalOpen && (
        <EditModal
          data={isEditing ? userData : {}} // Pass userData if editing, otherwise empty object
          onSave={handleSave}
          closeModal={() => setIsUserModalOpen(false)}
          isEditing={isEditing}
        />
      )}
    </div>
  );
};

export default UserMappingPage;
