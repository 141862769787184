import React, { useState, useEffect } from 'react';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTranslation } from 'react-i18next';
import './EditModal.css';

const EditModal = ({ data, onSave, closeModal, isEditing, isResourceModal = false }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState(data || {});

  useEffect(() => {
    setFormData(data);
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleQuillChange = (field, value) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const handleSave = () => {
    onSave(formData);
    closeModal();
  };

  const handleToggleChange = () => {
    setFormData(prevState => ({
      ...prevState,
      ispublished: !prevState.ispublished
    }));
  };

  if (isResourceModal) {
    return (
      <div className="resource-modal-backdrop">
        <div className="resource-modal-container">
          <h2>{t('editResource')}</h2>
          <div className="form-group">
            <label>Resource Name</label>
            <input
              type="text"
              value={formData.resourcename || ""}
              name="resourcename"
              onChange={handleChange}
              placeholder="Enter resource name"
            />
          </div>
          <div className="form-group">
            <label>Title</label>
            <input
              type="text"
              value={formData.resourcetitle || ""}
              name="resourcetitle"
              onChange={handleChange}
              placeholder="Enter title"
            />
          </div>
          <div className="form-group">
            <label>Introduction</label>
            <ReactQuill
              value={formData.introduction || ""}
              onChange={(value) => handleQuillChange('introduction', value)}
              placeholder="Enter introduction"
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <ReactQuill
              value={formData.resourcedescription || ""}
              onChange={(value) => handleQuillChange('resourcedescription', value)}
              placeholder="Enter description"
            />
          </div>
          <div className="form-group">
            <label>Course Content</label>
            <ReactQuill
              value={formData.coursecontent || ""}
              onChange={(value) => handleQuillChange('coursecontent', value)}
              placeholder="Enter course content"
            />
          </div>
          <div className="form-group">
            <label>Publish Resource</label>
            <label className="switch">
              <input
                type="checkbox"
                checked={formData.ispublished || false}
                onChange={handleToggleChange}
              />
              <span className="slider round"></span>
            </label>
            <span>{formData.ispublished ? "Published" : "Unpublished"}</span>
          </div>
          <div className="modal-actions">
            <button className="btn-primary" onClick={closeModal}>
              {t('close')}
            </button>
            <button className="btn-secondary" onClick={handleSave}>
              {t('save')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  const filteredFormData = Object.entries(formData).filter(
    ([key]) => key !== 'uuid' && key !== 'password' && key !== 'role'
  );

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal-content">
        <h2>{t('editDetails')}</h2>
        <form>
          <table className="edit-modal-table">
            <tbody>
              {filteredFormData.map(([key, value]) => (
                <tr key={key}>
                  <td>
                    <label htmlFor={key}>
                      {t(`labels.${key}`) || key}
                    </label>
                  </td>
                  <td>
                    <input
                      type="text"
                      id={key}
                      name={key}
                      value={value || ''}
                      onChange={handleChange}
                      disabled={key === 'type' || key === 'id'}
                      className="edit-modal-input"
                    />
                  </td>
                </tr>
              ))}
              {/* Publish Toggle */}
              <tr>
                <td>
                  <label htmlFor="isPublished">{t('labels.isPublished') || 'Published'}</label>
                </td>
                <td>
                  <label className="switch">
                    <input
                      type="checkbox"
                      id="isPublished"
                      checked={formData.isPublished || false}
                      onChange={handleToggleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                  <span>{formData.isPublished ? "Published" : "Unpublished"}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="button-row">
            <button type="button" className="edit-modal-save-button" onClick={handleSave}>
              {t('save')}
            </button>
            <button type="button" className="edit-modal-close-button" onClick={closeModal}>
              {t('close')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditModal;
