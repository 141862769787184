import React, { useState } from 'react';
import './AddMembersForm.css';
import { createUser } from '../../services/apiService';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const AddMembersForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize navigate hook
  const [formData, setFormData] = useState({
    userAccount: '',
    password: '',
    internalCustomerReferenceCode: '',
    companyName: '',
    name: '',
    surname: '',
    telephoneNumber: '',
    mobileNumber: '',
    email: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};

    // 10 digit mobile number validation
    if (!/^\d{10}$/.test(formData.mobileNumber)) {
      newErrors.mobileNumber = t('errors.invalidPhoneNumber');
    }

    // Email validation
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      newErrors.email = t('errors.invalidEmail');
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return; // If validation fails, prevent form submission
    }

    const userData = {
      email: formData.email,
      password: formData.password,
      userAccount: formData.userAccount,
      customerReferenceCode: formData.internalCustomerReferenceCode,
      companyName: formData.companyName,
      firstName: formData.name,
      surname: formData.surname,
      telephoneNumber: formData.telephoneNumber,
      mobileNumber: formData.mobileNumber,
    };

    try {
      await createUser(userData);
      console.log('User created successfully');
      navigate(-1); // Redirect to the previous page after successful creation
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  return (
    <div className="address-container">
      <h1 className="title">{t('add_members')}</h1>
      <form className="address-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="userAccount">{t('labels.userAccount')}</label>
            <input
              type="text"
              id="userAccount"
              name="userAccount"
              value={formData.userAccount}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">{t('labels.password')}</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="internalCustomerReferenceCode">{t('labels.internalCustomerReferenceCode')}</label>
            <input
              type="text"
              id="internalCustomerReferenceCode"
              name="internalCustomerReferenceCode"
              value={formData.internalCustomerReferenceCode}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="companyName">{t('labels.companyName')}</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="name">{t('labels.name')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="surname">{t('labels.surname')}</label>
            <input
              type="text"
              id="surname"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="telephoneNumber">{t('labels.telephoneNumber')}</label>
            <input
              type="text"
              id="telephoneNumber"
              name="telephoneNumber"
              value={formData.telephoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobileNumber">{t('labels.mobileNumber')}</label>
            <input
              type="text"
              id="mobileNumber"
              name="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
            />
            {errors.mobileNumber && <p className="error-message">{errors.mobileNumber}</p>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label htmlFor="email">{t('labels.email')}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="error-message">{errors.email}</p>}
          </div>
        </div>

        <button type="submit" className="submit-button">{t('save')}</button>
      </form>
    </div>
  );
};

export default AddMembersForm;
