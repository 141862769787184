import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import { Routes, Route, useNavigate } from "react-router-dom";
import AddMembersForm from "./pages/AddMembersForm/AddMembersForm";
import LoginPage from "./pages/LoginPage/LoginPage";
import ManageMembers from "./pages/ManageMembers/ManageMembers";

import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import LanguageSwitcher from "./components/LanguageSwitcher/LanguageSwitcher"; 
import { useTranslation } from "react-i18next";
import "./i18n";
import ManageCodes from "./pages/ManageCodes/ManageCodes";
import ManageResources from "./pages/ManageResources/ManageResources";
import UserResourceMapping from './pages/UserResourceMapping/UserResourceMapping';

function App() {
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = (status) => {
    setIsAuthenticated(status);
  };

  if (!isAuthenticated) {
    return <LoginPage onLogin={handleLogin} />;
  }

  return (
    <>
      <Navbar />
      <LanguageSwitcher />
      <div className="main-content">
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<ManageCodes />} />
            <Route path="/code" element={<ManageCodes />} />
            <Route path="/resources" element={<ManageResources />} />
            <Route path="/manage/members" element={<ManageMembers />} />
            <Route path="/add/members-form" element={<AddMembersForm />} />
            <Route path="/user-resource/:userId" element={<UserResourceMapping />} />
          </Routes>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default App;
