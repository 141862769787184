import React from "react";
import { useTranslation } from "react-i18next";
import "./DeleteConfirmationModal.css";

const DeleteConfirmationModal = ({ isOpen, closeModal, onDelete }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="delete-modal-overlay">
      <div className="delete-modal">
        <h2>{t('deleteConfirmationModal.confirmTitle')}</h2>
        <p>{t('deleteConfirmationModal.confirmMessage')}</p>
        <div className="delete-modal-actions">
          <button className="btn-delete-confirm" onClick={onDelete}>
            {t('deleteConfirmationModal.confirmButton')}
          </button>
          <button className="btn-delete-cancel" onClick={closeModal}>
            {t('deleteConfirmationModal.cancelButton')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
