import React, { useState, useEffect } from "react";
import "./ManageMembers.css";
import Filter from "../../components/Filter/Filter";
import { getAllUsers, createUser } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import AddUserModalManageMember from "./AddUserModalManageMember";

const ManageMembers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    email: "",
    companyname: "",
    firstname: "",
    surname: "",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);  // State for managing modal
  const [newUser, setNewUser] = useState({  // State for new user form with prefilled values
    email: "",
    password: "",
    userAccount: "",
    customerReferenceCode: "",
    companyName: "",
    firstName: "",
    surname: "",
    telephoneNumber: "",
    mobileNumber: "",
    roleId: process.env.REACT_APP_ROLE_ID  // Use role ID from .env
  });

    const fetchData = async () => {
      try {
        const response = await getAllUsers();
        setData(response);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleNewUserChange = (event) => {  // Handle input changes for form
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddUser = async (event) => {  // Handle user submission
    event.preventDefault();
    try {
      await createUser(newUser);  // Call API to create user
      fetchData();  // Refresh the user list
      closeModal();  // Close the modal after successful submission
      setNewUser({  // Reset form with empty values
        email: "",
        password: "",
        userAccount: "",
        customerReferenceCode: "",
        companyName: "",
        firstName: "",
        surname: "",
        telephoneNumber: "",
        mobileNumber: "",
        roleId: process.env.REACT_APP_ROLE_ID
      });
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const filteredData = data.filter((item) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      const regex = new RegExp(filters[key], 'i');
      return regex.test(item[key]?.toString() || '');
    });
  });

  const handleRowClick = (userId) => {
    navigate(`/user-resource/${userId}`);
  };

  return (
    <div className="container">
      <h1 className="title">{t("manage_members")}</h1>

      {/* Button to open the modal */}
      <button className="add-user-button" onClick={openModal}>
        {t("Add New User")}
      </button>

       {/* Add User Modal */}
       <AddUserModalManageMember
        isOpen={isModalOpen}
        onClose={closeModal}
        newUser={newUser}
        handleNewUserChange={handleNewUserChange}
        handleAddUser={handleAddUser}
        t={t}  // Pass translation object for localization
      />
      
      <Filter
        filters={filters}
        handleFilterChange={handleFilterChange}
        filterTypes={{
          email: "text",
          companyname: "text",
          firstname: "text",
          surname: "text",
        }}
      />

      {/* User Table */}
      <table className="custom-table">
        <thead>
          <tr>
            <th>{t("email")}</th>
            <th>{t("company_name")}</th>
            <th>{t("first_name")}</th>
            <th>{t("surname")}</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((user) => (
            <tr key={user.id} onClick={() => handleRowClick(user.id)}>
              <td>{user.email}</td>
              <td>{user.companyname}</td>
              <td>{user.firstname}</td>
              <td>{user.surname}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageMembers;

