import React, { useState, useEffect, useRef } from "react";
import * as FaIcons from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSidebarData } from "./SidebarData"; // Use the custom hook
import "./Navbar.css";
import { IconContext } from "react-icons";
import {jwtDecode} from "jwt-decode";
import { useTranslation } from 'react-i18next'; 

function Navbar() {
  const { t } = useTranslation();
  const [sidebar, setSidebar] = useState(true);
  const [dropdown, setDropdown] = useState({});
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [selectedSubItem, setSelectedSubItem] = useState(null); // State to track selected sub-item
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const showSidebar = () => setSidebar(!sidebar);

  const toggleDropdown = (index) => {
    setDropdown((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const token = localStorage.getItem("accessToken");
  let userName = "";

  if (token) {
    const decodedToken = jwtDecode(token);
    userName = decodedToken.name;
  }

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowUserDropdown(false);
    }
  };

  useEffect(() => {
    if (showUserDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showUserDropdown]);

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    navigate("/");
    window.location.reload();
  };

  const sidebarData = useSidebarData(); 

  return (
    <>
      <IconContext.Provider value={{ color: "#000" }}>
        <div className="navbar">
          <div className="user-info" onClick={() => setShowUserDropdown((prev) => !prev)}>
            <FaIcons.FaUser className="user-icon" />
            <span>{userName}</span>
          </div>
        </div>

        {showUserDropdown && (
          <div className="user-dropdown" ref={dropdownRef}>
            <button onClick={handleLogout}>{t('logout')}</button>
          </div>
        )}

        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul className="nav-menu-items">
            <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                {/* <img src="/pre.png" alt="Logo" className="logo" /> */}
                <span className="logo-text">LMS ADMIN</span>
              </Link>
            </li>
            {sidebarData.map((item, index) => (
              <div key={index}>
                {item.subNav ? (
                  <li className={item.cName}>
                    <div onClick={() => toggleDropdown(index)} className="nav-link">
                      {item.icon}
                      <span>{item.title}</span>
                      {dropdown[index] ? <FaIcons.FaAngleDown /> : <FaIcons.FaAngleDown />}
                    </div>
                  </li>
                ) : (
                  <li className={item.cName}>
                    <Link to={item.path} className="nav-link">
                      {item.icon}
                      <span>{item.title}</span>
                    </Link>
                  </li>
                )}
                {item.subNav &&
                  dropdown[index] &&
                  item.subNav.map((subItem, subIndex) => (
                    <li
                      key={subIndex}
                      className={`nav-sub-item ${
                        selectedSubItem === subItem.path ? "selected-sub-item" : ""
                      }`}
                    >
                      <Link
                        to={subItem.path}
                        onClick={() => setSelectedSubItem(subItem.path)}
                      >
                        <span>{subItem.title}</span>
                      </Link>
                    </li>
                  ))}
              </div>
            ))}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
