import React, { useState } from "react";
import "./LoginPage.css";
import { login } from "../../services/apiService"; // Adjust the import path based on your project structure
import { useTranslation } from 'react-i18next';

const LoginPage = ({ onLogin }) => {
  const { t } = useTranslation(); // Initialize the useTranslation hook
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await login(email, password);

      if (response && response.access_token) {
        console.log(response.access_token);
        localStorage.setItem("accessToken", response.access_token);
        onLogin(true);
      } else {
        setError(t("invalid_credentials"));
      }
    } catch (err) {
      setError(t("login_error"));
    }
  };

  return (
    <div className="login-page-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h1 className="login-title">{t("login")}</h1>
        <div className="login-input-group">
          <label htmlFor="email">{t("email")}</label>
          <input
            type="email"
            id="email"
            className="login-input-field"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="login-input-group">
          <label htmlFor="password">{t("password")}</label>
          <input
            type="password"
            id="password"
            className="login-input-field"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-submit">
          {t("login_button")}
        </button>
        {error && <div className="login-error-message">{error}</div>}
      </form>
    </div>
  );
};

export default LoginPage;
