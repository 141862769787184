import * as IoIcons from "react-icons/io";
import {jwtDecode} from "jwt-decode";
import { useTranslation } from 'react-i18next';

export const useSidebarData = () => {
  const { t } = useTranslation(); 
  const token = localStorage.getItem("accessToken");
  let userRole = "";

  if (token) {
    const decodedToken = jwtDecode(token);
    userRole = decodedToken.role;
  }

  return [ 
    {
      title: "Codes",
      path: "#",
      icon: <IoIcons.IoIosPricetag />,
      cName: "nav-text",
      subNav: [
        {
          title: "Redeem Code",
          path: "/code",
        },
      ],
    },
    {

    title: "Resources",
    path: "#",
    icon: <IoIcons.IoIosPaper />,
    cName: "nav-text",
    subNav: [
      {
        title: "Resources",
        path: "/resources",
      },
      // {
      //   title: t("recipient"),
      //   path: "/addressbook/recipient",
      // },
    ],
  
  },
    ...(userRole === "admin"
      ? [
          {
            title: t("manage_members"),
            path: "#",
            icon: <IoIcons.IoIosArchive />,
            cName: "nav-text",
            subNav: [
              {
                title: t("manage_members"),
                path: "/manage/members",
              },
            ],
          },
        ]
      : []),
  ];
};
