export const convertKeysInResponseToLowerCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => convertKeysInResponseToLowerCase(item));
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const lowerCaseKey = (typeof key === 'string' && key.toLowerCase) ? key.toLowerCase() : key;
      acc[lowerCaseKey] = convertKeysInResponseToLowerCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};
