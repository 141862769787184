import React from "react";
import "./AddUserModalManageMember.css"; 

const AddUserModalManageMember = ({
   isOpen,
  onClose,
  newUser,
  handleNewUserChange,
  handleAddUser,
  t
}) => {
  if (!isOpen) return null;  // If the modal is not open, don't render anything

  return (
    <div className="ManageMember-modal-overlay">
      <div className="ManageMember-modal-content">
        <h2>{t("Add New User")}</h2>

        <form className="add-user-form" onSubmit={handleAddUser}>
            
          <label>Email</label>  
          <input
            type="email"
            name="email"
            placeholder={t("Enter Email")}
            value={newUser.email}
            onChange={handleNewUserChange}
            required
          />
          
          <label>Password</label>
          <input
            type="password"
            name="password"
            placeholder={t("Enter Password")}
            value={newUser.password}
            onChange={handleNewUserChange}
            required
          />

          <label>User Account</label>
          <input
            type="text"
            name="userAccount"
            placeholder={t("User Account")}
            value={newUser.userAccount}
            onChange={handleNewUserChange}
            required
          />

          <label>Customer Reference Code</label>
          <input
            type="text"
            name="customerReferenceCode"
            placeholder={t("Enter Customer Reference Code")}
            value={newUser.customerReferenceCode}
            onChange={handleNewUserChange}
            required
          />
          
          <label>Company Name</label>
          <input
            type="text"
            name="companyName"
            placeholder={t("Enter Company Name")}
            value={newUser.companyName}
            onChange={handleNewUserChange}
            required
          />

          <label>First Name</label>
          <input
            type="text"
            name="firstName"
            placeholder={t("Enter First Name")}
            value={newUser.firstName}
            onChange={handleNewUserChange}
            required
          />

          <label>Surname</label>
          <input
            type="text"
            name="surname"
            placeholder={t("Enter Surname")}
            value={newUser.surname}
            onChange={handleNewUserChange}
            required
          />

          <label>Telephone Number</label>
          <input
            type="text"
            name="telephoneNumber"
            placeholder={t("Enter Telephone Number")}
            value={newUser.telephoneNumber}
            onChange={handleNewUserChange}
            required
          />

          <label>Mobile Number</label>
          <input
            type="text"
            name="mobileNumber"
            placeholder={t("Enter Mobile Number")}
            value={newUser.mobileNumber}
            onChange={handleNewUserChange}
            required
          />

           <input
            type="hidden"
            name="roleId"
            placeholder={t("Enter Role Id")}
            value={newUser.roleId}
            onChange={handleNewUserChange}
            required
          />

          {/* form buttons */}
           {/* Button container wrapping both buttons */}

           <div className="button-container">
           <button type="button" onClick={onClose} className="close-button">
          {t("Close")}
          </button>
          <button type="submit" className="save-button">
          {t("Save")}
          </button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default AddUserModalManageMember;