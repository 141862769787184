import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './LanguageSwitcher.css';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
    setIsDropdownOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isDropdownOpen]);

  return (
    <div className="language-switcher" ref={dropdownRef}>
      <button
        className="lang-button"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {currentLanguage === 'en' ? 'English' : 'ไทย'}
        <span className="dropdown-icon">🌐</span>
      </button>
      {isDropdownOpen && (
        <ul className="dropdown-menu">
          <li
            className={currentLanguage === 'en' ? 'selected' : ''}
            onClick={() => handleLanguageChange('en')}
          >
            English
          </li>
          <li
            className={currentLanguage === 'th' ? 'selected' : ''}
            onClick={() => handleLanguageChange('th')}
          >
            ไทย
          </li>
        </ul>
      )}
    </div>
  );
};

export default LanguageSwitcher;
