import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import './Filter.css';

const Filter = ({ filters, handleFilterChange, filterTypes }) => {
  const { t } = useTranslation(); 

  return (
    <div className="filter-container">
      {Object.keys(filters).map((key) => {
        const type = filterTypes[key] || 'text'; 
        const placeholderText = t(`labels.${key.split(/(?=[A-Z])/).join('').toLowerCase()}`); 

        return (
          <div className="filter-input-group" key={key}>
            {type === 'text' && <FaSearch className="search-icon" />}
            {type === 'text' && (
              <input
                type="text"
                name={key}
                placeholder={placeholderText}
                value={filters[key]}
                onChange={handleFilterChange}
                className="filter-input underline"
              />
            )}
            {/* {type === 'select' && (
              <select
                name={key}
                value={filters[key]}
                onChange={handleFilterChange}
                className="filter-input underline"
              >
                <option value="">{t('allStatus')}</option>
                <option value="Pending">{t('pending')}</option>
                <option value="Shipped">{t('shipped')}</option>
                <option value="Delivered">{t('delivered')}</option>
              </select>
            )} */}
            {/* {type === 'date' && (
              <input
                type="date"
                name={key}
                placeholder={t('createdAt')}
                value={filters[key]}
                onChange={handleFilterChange}
                className="filter-input underline"
              />
            )} */}
          </div>
        );
      })}
    </div>
  );
};

export default Filter;
